<template>
  <div class="gdi-container">
    <p class="text-2xl mb-6">Google Discover Insights</p>
    <filters :progress="progress" />
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab key="global"> Global </v-tab>
      <v-tab key="sessions"> Sessions </v-tab>
      <v-tab key="week"> Jours Semaine </v-tab>
      <v-tab key="words"> Mots/Caractères </v-tab>
      <v-tab key="lifetime"> Durée de vie </v-tab>
      <v-tab key="keywords"> Mots clés </v-tab>
    </v-tabs>
    <!--    <v-card class="mt-6">-->
    <v-tabs-items v-model="tab">
      <v-tab-item key="global">
        <GdiGlobalStats />
      </v-tab-item>
      <v-tab-item key="sessions">
        <GdiSessionStats />
      </v-tab-item>
      <v-tab-item key="week">
        <GdiWeekdayStats />
      </v-tab-item>
      <v-tab-item key="words">
        <GdiWordStats />
      </v-tab-item>
      <v-tab-item key="lifetime">
        <GdiLifetimeStats />
      </v-tab-item>
      <v-tab-item key="keywords">
        <GdiKeywordStats />
      </v-tab-item>
    </v-tabs-items>
    <!--    </v-card>-->
  </div>
</template>
<script>
import GdiGlobalStats from "@/components/google-discover/gd-insights/tabs/GdiGlobalStats.vue";
import GdiSessionStats from "@/components/google-discover/gd-insights/tabs/GdiSessionStats.vue";
import GdiWeekdayStats from "@/components/google-discover/gd-insights/tabs/GdiWeekdayStats.vue";
import GdiWordStats from "@/components/google-discover/gd-insights/tabs/GdiWordStats.vue";
import GdiLifetimeStats from "@/components/google-discover/gd-insights/tabs/GdiLifetimeStats.vue";
import GdiKeywordStats from "@/components/google-discover/gd-insights/tabs/GdiKeywordStats.vue";
import Filters from "@/components/google-discover/gd-insights/filters/Filters.vue";
import debounce from "lodash/debounce";
import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import axios, { cacheTime } from "@axios";

export default {
  name: "GDInsights",
  components: {
    Filters,
    GdiGlobalStats,
    GdiSessionStats,
    GdiWeekdayStats,
    GdiWordStats,
    GdiLifetimeStats,
    GdiKeywordStats,
  },
  data() {
    return {
      tab: null,
      loadingCount: 0,
      urlCalls: 7,
      progress: 0,
    };
  },
  setup() {
    const { isLoading, axiosGet, axiosPost } = useAxios();

    return {
      isLoading,
      axiosGet,
      axiosPost,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),

    async getData() {
      if (this.applyFilters && !this.isLoading) {
        const params = {
          dates: this.dates,
          sites: this.sites,
          categories: this.categories,
          periodType: this.periodType,
        };
        try {
          const {
            respSessionsInGD,
            respSessionsNotInGD,
            globalStats,
            // categoriesDistributionGA,
            categoriesDistributionGD,
            weekdaysStats,
            keywordsStats,
            lifetimeStats,
          } = await runParallelAsyncs({
            respSessionsInGD: this.axiosPost(
              "google-discover/sessions-in-discover",
              params
            ),
            respSessionsNotInGD: this.axiosPost(
              "google-discover/sessions-not-in-discover",
              params
            ),
            globalStats: this.axiosPost("google-discover/global-stats", params),
            categoriesDistributionGD: this.axiosPost(
              "google-discover/categories-distribution-gd",
              params
            ),
            weekdaysStats: this.axiosPost(
              "google-discover/weekdays-stats",
              params
            ),
            keywordsStats: this.axiosPost(
              "google-discover/keywords-stats",
              params
            ),
            lifetimeStats: this.axiosPost(
              "google-discover/lifetime-stats",
              params
            ),
          });
          await this.$store.dispatch("gd/updateData", {
            globalStats: globalStats.data,
            // categoriesDistributionGA: categoriesDistributionGA.data,
            categoriesDistributionGD: categoriesDistributionGD.data,
            sessionsInGD: respSessionsInGD.data,
            sessionsNotInGD: respSessionsNotInGD.data,
            weekdaysStats: weekdaysStats.data,
            keywordsStats: keywordsStats.data,
            lifetimeStats: lifetimeStats.data,
          });
        } catch (err) {
          console.warn(err);
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },

    countLoadingUrls(urls) {
      if (
        !urls["google-discover_insights"] ||
        (!urls["google-discover_insights"]["get"] &&
          !urls["google-discover_insights"]["post"])
      )
        return 0;
      let getCount = 0;
      let postCount = 0;
      if ("get" in urls["google-discover_insights"]) {
        getCount = urls["google-discover_insights"]["get"].length;
      }
      if ("post" in urls["google-discover_insights"]) {
        postCount = urls["google-discover_insights"]["post"].length;
      }
      return getCount + postCount;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    dates() {
      return this.$store.getters["gd/getDates"];
    },
    sites() {
      return this.$store.getters["gd/getSites"];
    },
    categories() {
      return this.$store.getters["gd/getCategories"];
    },
    periodType() {
      return this.$store.getters["gd/getPeriodType"];
    },
    loadingUrls() {
      return this.$store.getters["axios/getLoadingUrls"];
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    loadingUrls() {
      const count = this.countLoadingUrls(this.loadingUrls);
      this.progress = Math.round(
        ((this.urlCalls - count) / this.urlCalls) * 100
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-tabs-items {
  background-color: #f4f5fa;
}
/* remove links underline */
.gdi-container ::v-deep a {
  text-decoration: none !important;
}
.gdi-container ::v-deep tbody tr:nth-of-type(even) {
  background-color: rgba(0, 1, 100, 0.08);
}
.gdi-container ::v-deep tbody tr:hover {
  background-color: #10396f !important;
  td {
    color: white !important;
    a {
      color: white !important;
    }
  }
}
</style>
